import ReactHabitat from 'react-habitat'
import chain from 'ramda/es/chain'
import compose from 'ramda/es/compose'
import map from 'ramda/es/map'
import toPairs from 'ramda/es/toPairs'
import createStore from '../../redux/create'
import DomFactory from './DomFactory'
import { getDuplicateKeysString, objectsShareKey } from '../../util/state'

const contexts = [
  require.context('../../components/', true, /habitat\.js$/),
  require.context('../../../vc_elements/', true, /habitat\.js$/),
]

const asyncContexts = [
  require.context('../../components/', true, /habitat-async\.js$/),
  require.context('../../../vc_elements/', true, /habitat-async\.js$/),
]

const getRegisteredComponents = compose(
  chain(context => map(key => context(key).default)(context.keys()))
)

class ReactApp extends ReactHabitat.Bootstrapper {
  constructor() {
    super()

    this.store = createStore()

    this.updateComponents(contexts, asyncContexts)

    if (module.hot) {
      const dependencies = [...contexts, ...asyncContexts].map(c => c.id)

      module.hot.accept(dependencies, () => {
        const contexts = [
          require.context('../../components/', true, /habitat\.js$/),
          require.context('../../../vc_elements/', true, /habitat\.js$/),
        ]

        const asyncContexts = [
          require.context('../../components/', true, /habitat-async\.js$/),
          require.context('../../../vc_elements/', true, /habitat-async\.js$/),
        ]

        this.dispose()
        this.updateComponents(contexts, asyncContexts)
      })
    }
  }

  updateComponents(contexts, asyncContexts) {
    // Create a new container builder
    const builder = new ReactHabitat.ContainerBuilder()

    builder.factory = new DomFactory(this.store)

    const components = getRegisteredComponents(contexts)
    const asyncComponents = getRegisteredComponents(asyncContexts)

    const allComponents = [...components, ...asyncComponents]

    const componentPairs = chain(toPairs, components)
    const asyncComponentPairs = chain(toPairs, asyncComponents)

    if (
      process.env.NODE_ENV !== 'production' &&
      objectsShareKey(allComponents)
    ) {
      const duplicateKeys = getDuplicateKeysString(allComponents)
      // eslint-disable-next-line
      console.error(
        `react-habitat: there are duplicate components registered under the following names: ${duplicateKeys}`,
        '\nplease check your habitat.js and habitat-async.js files!'
      )
    }

    // Register components with react habitat
    componentPairs.forEach(([name, component]) =>
      builder.register(component).as(name)
    )
    asyncComponentPairs.forEach(([name, component]) =>
      builder.registerAsync(component).as(name)
    )

    // Finally, set the container
    this.setContainer(builder.build())
  }

  getStore() {
    return this.store
  }
}

// Always export a 'new' instance so it immediately evokes
export default new ReactApp()
