import { connectRoutes } from 'redux-first-router'
import queryString from 'query-string'

import { FRONTPAGE, POST } from './routes'

export const routesMap = {
  [FRONTPAGE]: '/',
  [POST]: '/blog/:slug',
}

const router = connectRoutes(routesMap, {
  querySerializer: queryString,
})

export const middleware = router.middleware
export const enhancer = router.enhancer

export default router.reducer
